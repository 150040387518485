import { eventWithTime, record } from 'rrweb'
import rrwebPlayer from 'rrweb-player'
import { pack, unpack } from '@rrweb/packer'
import { RrwebEventExporter } from './exporter'
import { SessionDebuggerConfigs, SessionDebuggerOptions } from '../types'
import { getRecordConsolePlugin } from '@rrweb/rrweb-plugin-console-record'

export class RecorderBrowserSDK {
  private stopFn?: () => void
  private config?: SessionDebuggerOptions
  private exporter: RrwebEventExporter | undefined
  private events: Array<eventWithTime> = []

  constructor() {}

  init(config: SessionDebuggerConfigs): void {
    this.config = config
    this.exporter = new RrwebEventExporter(config.exporterApiBaseUrl || '', config.apiKey)
  }

  start(sessionId): void {
    if (!this.config) {
      throw new Error('Configuration not initialized. Call init() before start().')
    }
    this.stopFn = record({
      packFn: pack,
      recordCanvas: true,
      maskAllInputs: true,
      plugins: [getRecordConsolePlugin({ level: ['info', 'log', 'warn', 'error'] })],
      emit: (event) => {
        if (this.exporter) {
          const timestamp = Date.now()
          this.exporter.send({ event, debugSessionId: sessionId, timestamp })
          this.events.push(event)
          localStorage.setItem('mp-replay-rrwebEvents', JSON.stringify(this.events))
        }
      },
    })
  }

  stop(): void {
    if (this.stopFn) {
      this.stopFn()
    }
  }

  preview(): void {
    const storedEvents = localStorage.getItem('mp-replay-rrwebEvents')
    if (storedEvents) {
      const events = JSON.parse(storedEvents)
      const replayContainer = document.getElementById('mp-preview-debugger-recording')
      new rrwebPlayer({
        target: replayContainer!,
        props: {
          events: events.map(unpack),
          autoPlay: false,
          skipInactive: true,
          showController: true,
          width: replayContainer?.offsetWidth,
          height: replayContainer?.offsetHeight,
        },
      }).getReplayer()
    }
  }

  clearStoredEvents(): void {
    localStorage.removeItem('mp-replay-rrwebEvents')
    this.events = []
    const replayContainer = document.getElementById('mp-preview-debugger-recording')
    if (replayContainer) {
      replayContainer.innerHTML = ''
    }
  }

}
