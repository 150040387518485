import { Observable } from 'lib0/observable'
import {
  CloseIcon,
  LogoSvg,
  CapturingIcon,
  RecordIcon,
  CheckmarkIcon,
} from './icons'
import { SessionDebuggerConfigs } from 'src/types'

export class SessionWidget extends Observable<'toggle' | 'pause' | 'cancel' | 'preview'> {
  private readonly recorderButton: HTMLButtonElement
  private readonly initialPopover: HTMLElement
  private readonly finalPopover: HTMLElement
  private readonly previewModal: HTMLElement
  private _isStarted: boolean = false
  private _isPaused: boolean = false
  private _isDragging: boolean = false
  private _dragStarted: boolean = false
  private _recorderPlacement: string = ''
  private _error: string = ''

  public get error(): string {
    return this._error
  }

  public set error(v: string) {
    this._error = v
    this.updateTooltip()
  }

  public set isStarted(v: boolean) {
    this._isStarted = v
    this.recorderButton.classList.toggle('is-started', this._isStarted)
    if (this._isStarted) {
      this.updateButton(
        CapturingIcon,
        'Click to stop your recording',
        ['animate-rotate'],
        ['animate-bounce'],
      )
    }
  }

  public set isPaused(v: boolean) {
    this._isPaused = v
  }

  constructor() {
    super()

    this.recorderButton = document.createElement('button')
    this.initialPopover = document.createElement('div')
    this.finalPopover = document.createElement('div')
    this.previewModal = document.createElement('div')

    this.setRecorderButtonProps()
    this.setInitialPopoverProps()
    this.setFinalPopoverProps()
    this.setPreviewModalProps()
  }

  private updateTooltip() {
    if (this._error) {
      this.recorderButton.dataset['tooltip'] = this._error
    }
  }

  private setRecorderButtonProps() {
    this.recorderButton.className = 'mp-session-debugger-button'
    this.recorderButton.dataset['tooltip'] = 'Click to record a bug'
    this.recorderButton.innerHTML = RecordIcon
  }

  private setInitialPopoverProps() {
    this.initialPopover.className = 'mp-session-debugger-popover hidden'
    this.initialPopover.innerHTML = `
    <div class="mp-session-debugger-popover-content">
      <div class="mp-session-debugger-popover-header">
        <div class="mp-session-debugger-popover-logo">${LogoSvg}</div>
      </div>
      <div class="mp-session-debugger-popover-body">
        <h2>Encountered an issue?</h2>
        <p>Help us improve by sharing what went wrong. We'll record your steps, so we can see exactly what happened.</p>
        <button class="mp-session-debugger-popover-button mp-start-recording">Start bug-hunting!</button>
      </div>
    </div>`
  }

  private setFinalPopoverProps() {
    this.finalPopover.className = 'mp-session-debugger-popover hidden'
    this.finalPopover.innerHTML = `
    <div class="mp-session-debugger-popover-content">
      <div class="mp-session-debugger-popover-header">
        <div class="mp-session-debugger-popover-logo">${LogoSvg}</div>
        <button class="mp-session-debugger-dismiss-button">Dismiss report</button>
      </div>
      <div class="mp-session-debugger-popover-body">
        <h2>Done capturing?</h2>
        <p>Click the button below to send your bug report. Optionally, feel free to send a message to Multiplayer.</p>
        <textarea placeholder="Add a comment..." class="mp-session-debugger-popover-textarea"></textarea>
        <div class="mp-session-debugger-popover-footer">
          <button class="mp-session-debugger-popover-button mp-preview-recording">Preview</button>
          <button class="mp-session-debugger-popover-button mp-stop-recording">Send</button>
        </div>
      </div>
    </div>`
  }

  private setPreviewModalProps() {
    this.previewModal.className = 'mp-session-debugger-modal hidden'
    this.previewModal.innerHTML = `
      <div class="mp-session-debugger-modal-backdrop"></div>
      <div class="mp-session-debugger-modal-content">
      <div class="mp-session-debugger-modal-header">
        <header>Preview recording</header>
        <button class="mp-session-debugger-modal-close" aria-label="Close">&times;</button>
      </div>
        <div class="mp-session-debugger-modal-body" id="mp-preview-debugger-recording">

        </div>
        <div class="mp-session-debugger-modal-footer">
          <button class="mp-session-debugger-modal-button mp-dismiss-recording">Dismiss report</button>
          <button class="mp-session-debugger-modal-button mp-send-recording">Send bug report</button>
        </div>
      </div>
    `
  }

  init(options: SessionDebuggerConfigs) {
    this.addEventListeners()
    this.appendElements(
      this.recorderButton,
      this.initialPopover,
      this.finalPopover,
      this.previewModal,
    )
    if (options.recordButtonPlacement) {
      this.recorderButton.classList.add(options.recordButtonPlacement)
      this._recorderPlacement = options.recordButtonPlacement
    }
    this.addRecorderDragFunctionality()
  }

  private addRecorderDragFunctionality() {
    this._isDragging = false
    this._dragStarted = false
    let isOnLeftHalfOfScreen = false

    const loadStoredPosition = () => {
      const savedPosition = localStorage.getItem('mp-recorder-button-position')
      if (!savedPosition) {
        return
      }
      const { right, bottom } = JSON.parse(savedPosition)

      if (right !== null && bottom !== null) {
        requestAnimationFrame(() => {
          this.recorderButton.classList.remove(this._recorderPlacement)
          this.recorderButton.style.right = `${right}px`
          this.recorderButton.style.bottom = `${bottom}px`
          isOnLeftHalfOfScreen = Number(right) > window.innerWidth / 2
          this.recorderButton.classList.toggle(
            'button-leftside',
            isOnLeftHalfOfScreen,
          )
          this.updatePopoverPosition()
        })
      }
    }

    const savePosition = (right, bottom) => {
      const position = { right, bottom }
      localStorage.setItem('mp-recorder-button-position', JSON.stringify(position))
    }

    this.recorderButton.addEventListener('mousedown', (e) => {
      this.recorderButton.classList.add('no-hover')
      this._isDragging = true
      this._dragStarted = false
      const startX = e.clientX
      const startY = e.clientY
      const buttonRect = this.recorderButton.getBoundingClientRect()
      const viewportWidth = window.innerWidth
      const viewportHeight = window.innerHeight

      const onMouseMove = (moveEvent) => {
        const deltaX = moveEvent.clientX - startX
        const deltaY = moveEvent.clientY - startY

        // If mouse moved significantly, consider it a drag
        if (Math.abs(deltaX) > 3 || Math.abs(deltaY) > 3) {
          this._dragStarted = true
          this.recorderButton.classList.remove(this._recorderPlacement)
          this.updatePopoverPosition()

          const newLeft = Math.max(
            0,
            Math.min(viewportWidth - buttonRect.width, buttonRect.left + deltaX),
          )
          const newTop = Math.max(
            0,
            Math.min(
              viewportHeight - buttonRect.height,
              buttonRect.top + deltaY,
            ),
          )
          const newRight = viewportWidth - newLeft - buttonRect.width
          const newBottom = viewportHeight - newTop - buttonRect.height
          isOnLeftHalfOfScreen = newRight > window.innerWidth / 2

          requestAnimationFrame(() => {
            this.recorderButton.style.right = `${newRight}px`
            this.recorderButton.style.bottom = `${newBottom}px`
          })
        }
      }

      const onMouseUp = () => {
        if (this._isDragging) {
          this.recorderButton.classList.toggle(
            'button-leftside',
            isOnLeftHalfOfScreen,
          )
          this._isDragging = false
          document.body.style.userSelect = ''

          const finalRight = parseFloat(this.recorderButton.style.right)
          const finalBottom = parseFloat(this.recorderButton.style.bottom)
          savePosition(finalRight, finalBottom)

          if (!this._dragStarted) {
            this.onRecordingButtonClick()
          }

          document.removeEventListener('mousemove', onMouseMove)
          document.removeEventListener('mouseup', onMouseUp)
        }
        this.recorderButton.classList.remove('no-hover')
      }

      document.addEventListener('mousemove', onMouseMove)
      document.addEventListener('mouseup', onMouseUp)

      document.body.style.userSelect = 'none'
      e.preventDefault()
    })

    loadStoredPosition()
  }

  private updatePopoverPosition() {
    const buttonRect = this.recorderButton.getBoundingClientRect()
    // Todo update fixed height and width calculation
    const popoverHeight = this._isStarted ? 400 : 300 // fixed popover heights, change if popovers' content changed
    const popoverWidth = 300 // fixed popover width, change if popovers' content changed

    const distance = 25 // 25px for distance
    const viewportWidth = window.innerWidth
    const viewportHeight = window.innerHeight
    let newBottom = viewportHeight - buttonRect.top + distance
    let newRight = viewportWidth - buttonRect.right

    // Adjust if popover goes below the viewport
    if (newBottom + popoverHeight > viewportHeight) {
      newBottom = viewportHeight - (buttonRect.bottom + popoverHeight)
    }

    // Adjust if popover goes to the left of the viewport
    if (newRight + popoverWidth > viewportWidth) {
      newRight = viewportWidth - (buttonRect.left + popoverWidth)
    }

    requestAnimationFrame(() => {
      this.initialPopover.style.right = `${newRight}px`
      this.initialPopover.style.bottom = `${newBottom}px`
      this.initialPopover.style.left = 'unset'
      this.finalPopover.style.right = `${newRight}px`
      this.finalPopover.style.bottom = `${newBottom}px`
      this.finalPopover.style.left = 'unset'
    })
  }

  private addEventListeners() {
    // initial popover listeners
    this.addListener(this.initialPopover, '.mp-start-recording', this.startRecording.bind(this))

    // final popover listeners
    this.addListener(this.finalPopover, '.mp-stop-recording', this.handleStopRecording.bind(this))
    this.addListener(this.finalPopover, '.mp-preview-recording', this.handlePreviewRecording.bind(this))
    this.addListener(this.finalPopover, '.mp-session-debugger-dismiss-button', this.handleDismissRecording.bind(this))

    // preview modal listeners
    this.addListener(this.previewModal, '.mp-session-debugger-modal-close', this.handleModalClose.bind(this))
    this.addListener(this.previewModal, '.mp-dismiss-recording', this.handleDismissRecording.bind(this))
    this.addListener(this.previewModal, '.mp-send-recording', this.handleSendRecording.bind(this))
  }

  private handleStopRecording() {
    this.onStop()
    this.finalPopover?.classList.add('hidden')
    this.updateRecordingButton(
      CheckmarkIcon,
      'We\'ve sent it over! Thanks!',
      ['animate-bounce', 'animate-rotate'],
      ['mp-button-blue'],
    )
    this.resetRecordingButton()
  }

  private handlePreviewRecording() {
    this.previewModal.classList.remove('hidden')
    this.emit('preview', [])
  }

  private handleDismissRecording() {
    this.onCancel()
    this.finalPopover.classList.toggle('hidden')
    this.previewModal.classList.add('hidden')
    this.updateRecordingButton(
      RecordIcon,
      'Click to record a bug',
      ['animate-bounce', 'animate-rotate'],
    )
    const textarea = document.querySelector(
      '.mp-session-debugger-popover-textarea',
    ) as HTMLTextAreaElement
    textarea.value = ''
  }

  private handleModalClose() {
    this.previewModal.classList.toggle('hidden')
    const replayContainer = document.getElementById('mp-preview-debugger-recording')
    if (replayContainer) {
      replayContainer.innerHTML = ''
    }
  }

  private handleSendRecording() {
    this.previewModal.classList.toggle('hidden')
    this.onStop()
    this.finalPopover?.classList.add('hidden')
    this.updateRecordingButton(
      CheckmarkIcon,
      'We\'ve sent it over! Thanks!',
      ['animate-bounce', 'animate-rotate'],
      ['mp-button-blue'],
    )
    this.resetRecordingButton()
  }

  private updateRecordingButton(icon: string, text: string, addClasses: string[], removeClasses: string[] = []) {
    this.updateButton(icon, text, addClasses, removeClasses)
  }

  private resetRecordingButton() {
    setTimeout(() => {
      this.updateButton(RecordIcon, 'Click to record a bug', ['mp-button-blue'])
    }, 1500)
  }


  private appendElements(...elements: HTMLElement[]) {
    elements.forEach((element) => document.body.appendChild(element))
  }

  private addListener(
    element: HTMLElement | null,
    selector: string,
    handler: EventListener,
    event: string = 'click',
  ) {
    element?.querySelector(selector)?.addEventListener(event, handler)
  }

  private onRecordingButtonClick() {
    if (this._isPaused) {
      this.onCancel()
      this.finalPopover.classList.add('hidden')
      this.updateButton(RecordIcon, 'Click to record a bug', [
        'animate-bounce',
        'animate-rotate',
      ])
      return
    }

    if (this._isStarted) {
      this.finalPopover.classList.toggle('hidden')
      this.updateButton(CloseIcon, 'Click to cancel', [
        'animate-rotate',
        'animate-bounce',
      ])
      this.onPause()
    } else {
      const isPopoverHidden = this.initialPopover.classList.contains('hidden')
      this.initialPopover.classList.toggle('hidden', !isPopoverHidden)
      this.updateButton(
        isPopoverHidden ? CloseIcon : RecordIcon,
        isPopoverHidden ? 'Click to cancel' : 'Click to record a bug',
        ['animate-rotate', 'animate-bounce'],
      )
    }
  }

  private startRecording() {
    this.initialPopover.classList.add('hidden')
    this.updateButton(
      CapturingIcon,
      'Click to stop your recording',
      ['animate-rotate'],
      ['animate-bounce'],
    )
    this.onStart()
  }

  private updateButton(
    innerHTML: string,
    tooltip: string,
    classesToRemove?: string[],
    classesToAdd?: string[],
  ) {
    if (!this.recorderButton) return
    this.recorderButton.innerHTML = innerHTML
    this.recorderButton.dataset['tooltip'] = tooltip
    if (classesToRemove) {
      this.recorderButton.classList.remove(...classesToRemove)
    }
    if (classesToAdd) {
      this.recorderButton.classList.add(...classesToAdd)
    }
  }

  private onStart() {
    if (!this.recorderButton) return
    this.emit('toggle', [true])
  }

  private onStop() {
    if (!this.recorderButton) return

    const textarea = document.querySelector(
      '.mp-session-debugger-popover-textarea',
    ) as HTMLTextAreaElement
    const textareaValue = textarea ? textarea.value : null

    this.emit('toggle', [false, textareaValue])
    textarea.value = ''
  }

  private onPause() {
    this.emit('pause', [])
  }

  private onCancel() {
    this.emit('cancel', [])
  }

  enable() {
    if (!this.recorderButton) return
    this.recorderButton.disabled = false
    this.recorderButton.style.opacity = '1'
  }

  disable() {
    if (!this.recorderButton) return
    this.recorderButton.disabled = true
    this.recorderButton.style.opacity = '0.5'
  }

  destroy() {
    if (!this.recorderButton) return
    document.body.removeChild(this.recorderButton)
  }
}
