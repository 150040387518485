import { createContext, useContext } from "react";
import { useParams } from "react-router-dom";
import posthog from "posthog-js";

import { detectDeviceAndOS } from "shared/helpers/deviceDetector.helpers";
import { useAuth } from "shared/providers/AuthContext";

interface IAnalyticsContext {
  trackEvent: (eventName: string, properties: any) => void;
}

const AnalyticsContext = createContext<IAnalyticsContext>(null);

export const AnalyticsProvider = ({ children }) => {
  const { deviceType, os } = detectDeviceAndOS();
  const { user } = useAuth();
  const { workspaceId } = useParams();

  const trackEvent = (eventName: string, properties: any) => {
    posthog.capture(eventName, {
      workspaceId,
      userId: user._id,
      userEmail: user.primaryEmail,
      fullUserName:
        user.firstName && user.lastName
          ? `${user.firstName} ${user.lastName}`
          : user.workspaceUser?.username,
      operationSystem: os,
      deviceType,
      ...properties,
    });
  };

  return (
    <AnalyticsContext.Provider value={{ trackEvent }}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export function useAnalytics() {
  const context = useContext(AnalyticsContext);
  if (context === null) {
    throw new Error("useAnalytics must be used within AnalyticsProvider");
  }
  return context;
}
