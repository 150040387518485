export const LogoSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="30" height="24" viewBox="0 0 30 24" fill="none">
<path d="M28.8324 8.83643L23.5495 6.85854L21.4856 6.08553L23.6001 0.4375L15 3.65769L6.39963 0.4375L8.51441 6.08585L6.45046 6.85885L1.16757 8.83674L0.625 9.03974L9.10095 12.0981C10.0891 12.4548 10.9201 13.1265 11.4758 13.9952C11.6632 14.2883 11.8194 14.6036 11.9398 14.9369L15 23.4076L18.0602 14.9369C18.1806 14.6036 18.3368 14.288 18.5242 13.9952C19.0802 13.1265 19.9112 12.4545 20.8991 12.0981L29.375 9.03974L28.8324 8.83674V8.83643ZM19.779 10.6434C18.2872 11.1816 17.1126 12.3563 16.5744 13.848L15.014 18.173L11.5182 8.83643L10.7776 6.85854L10.2456 5.43757L9.57367 3.64272L12.3068 4.66612L18.1631 6.85885L20.8233 7.85481L23.4457 8.83674L24.104 9.08308L19.779 10.6438V10.6434Z" fill="#473CFB" style="fill:#473CFB;fill:color(display-p3 0.2784 0.2353 0.9843);fill-opacity:1;"/>
</svg>`

export const RecordIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
<path fill-rule="evenodd" clip-rule="evenodd" d="M6.68926 5.30356C6.56568 5.38721 6.39976 5.37561 6.29459 5.26937L3.58782 2.53477C3.46424 2.40992 3.47196 2.20492 3.60862 2.09483C5.2319 0.786982 7.28494 0 9.51866 0C11.7535 0 13.8066 0.787042 15.4308 2.09586C15.5674 2.20596 15.5752 2.41091 15.4516 2.53577L12.7468 5.26931C12.6416 5.37558 12.4757 5.38719 12.3521 5.30353C11.5393 4.75345 10.571 4.42281 9.52066 4.42281C8.47036 4.42281 7.50203 4.75346 6.68926 5.30356ZM16.4926 3.4303C16.6163 3.30527 16.8197 3.31303 16.9288 3.45121C18.2224 5.08933 19.0001 7.15932 19.0001 9.4116C19.0001 11.6671 18.2204 13.7392 16.9238 15.3785C16.8147 15.5165 16.6114 15.5242 16.4877 15.3992L13.7872 12.6701C13.682 12.5638 13.6708 12.3962 13.7538 12.2716C14.3006 11.451 14.6291 10.4727 14.6291 9.4116C14.6291 8.35454 14.3016 7.37925 13.756 6.56083C13.6728 6.43616 13.6841 6.26857 13.7893 6.16224L16.4926 3.4303ZM5.21676 12.6712C5.322 12.5649 5.3333 12.3974 5.2502 12.2727C4.70331 11.4522 4.374 10.4737 4.374 9.41184C4.374 8.35469 4.70232 7.37949 5.24808 6.56106C5.33123 6.43637 5.31996 6.26872 5.2147 6.16241L2.50855 3.4293C2.38482 3.30434 2.18146 3.31213 2.07236 3.45028C0.77864 5.08841 0 7.15845 0 9.41184C0 11.6684 0.78066 13.7406 2.07831 15.3799C2.18749 15.5178 2.39066 15.5255 2.51429 15.4006L5.21676 12.6712ZM12.3323 13.707C12.4559 13.6231 12.6221 13.6346 12.7273 13.741L15.4277 16.4691C15.5513 16.594 15.5435 16.7991 15.4068 16.9091C13.7837 18.215 11.7327 19 9.49998 19C7.2693 19 5.21837 18.2159 3.59619 16.9102C3.45943 16.8001 3.45169 16.595 3.57533 16.4702L6.27769 13.7409C6.38296 13.6346 6.54906 13.6231 6.67267 13.707C7.48459 14.2577 8.45278 14.5883 9.50198 14.5883C10.5522 14.5883 11.5204 14.2578 12.3323 13.707Z" fill="currentColor"/>
</svg>`

export const CloseIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
<path d="M13 11L10 14M10 14L7 11M10 14L10 6M10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10C19 14.9706 14.9706 19 10 19Z" stroke="#718096" style="stroke:#718096;stroke:color(display-p3 0.4431 0.5020 0.5882);stroke-opacity:1;" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`

export const CapturingIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<circle cx="12" cy="12" r="4" fill="#E53E3E" style="fill:#E53E3E;fill:color(display-p3 0.8980 0.2431 0.2431);fill-opacity:1;"/>
<circle cx="12" cy="12" r="7.5" stroke="#E53E3E" style="stroke:#E53E3E;stroke:color(display-p3 0.8980 0.2431 0.2431);stroke-opacity:1;"/>
<circle opacity="0.2" cx="12" cy="12" r="11.5" stroke="#E53E3E" style="stroke:#E53E3E;stroke:color(display-p3 0.8980 0.2431 0.2431);stroke-opacity:1;"/>
</svg>`

export const CheckmarkIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<g id="Left Icon">
<path id="Icon" fill-rule="evenodd" clip-rule="evenodd" d="M20.0481 6.35147C20.5168 6.8201 20.5168 7.5799 20.0481 8.04853L10.4481 17.6485C9.97951 18.1172 9.21971 18.1172 8.75108 17.6485L3.95108 12.8485C3.48245 12.3799 3.48245 11.6201 3.95108 11.1515C4.41971 10.6828 5.17951 10.6828 5.64814 11.1515L9.59961 15.1029L18.3511 6.35147C18.8197 5.88284 19.5795 5.88284 20.0481 6.35147Z" fill="white" style="fill:white;fill-opacity:1;"/>
</g>
</svg>`
