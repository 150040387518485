import {
  useState,
  useContext,
  createContext,
  useEffect,
  useCallback,
} from "react";
import useMessage from "shared/hooks/useMessage";
import { IUserWithWorkspaces } from "shared/models/interfaces";

import * as AuthService from "shared/services/auth.service";

interface IAuthContext {
  user: IUserWithWorkspaces;
  sessions: IUserWithWorkspaces[];
  loading: boolean;
  authorized: boolean;
  signOut: (redirect?: string) => Promise<void>;
  signIn: () => Promise<IUserWithWorkspaces>;
  setUser: (arg: IUserWithWorkspaces) => void;
  setLoading: (arg: boolean) => void;
  updateSessions: () => Promise<void>;
  clearAuthData: () => void;
  setSession: (arg: IUserWithWorkspaces) => Promise<void>;
}

export const AuthContext = createContext<IAuthContext | null>(null);

export const AuthProvider = ({ children }) => {
  const message = useMessage();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [sessions, setSessions] = useState(null);
  const [authorized, setAuthorized] = useState(false);

  const signIn = async () => {
    return getUserAndSessions();
  };

  const updateSessions = useCallback(async () => {
    return getUserAndSessions();
  }, []);

  const clearAuthData = useCallback(() => {
    setAuthorized(false);
    setSessions(null);
    setUser(null);
  }, []);

  const signOut = useCallback(
    async (redirectTo?: string) => {
      try {
        await AuthService.sigOut();
      } catch (error) {
        console.warn("Auth error!");
      }
      if (redirectTo) localStorage.setItem("redirectTo", redirectTo);
      clearAuthData();
    },
    [clearAuthData]
  );

  const getUserAndSessions = useCallback(async () => {
    try {
      const { current, sessions } = await AuthService.getUserSession();
      const currentSession = sessions.find(
        (s: IUserWithWorkspaces) => s._id === current
      );
      setUser(currentSession);
      setSessions(sessions);
      setAuthorized(true);
      setLoading(false);
      return currentSession;
    } catch (error) {
      clearAuthData();
      setLoading(false);
    }
  }, []);

  const setSession = useCallback(
    async (session: IUserWithWorkspaces) => {
      try {
        await AuthService.setUserSession(session._id);
        setUser(session);
      } catch (error) {
        message.handleError(error);
      }
    },
    [message]
  );

  useEffect(() => {
    getUserAndSessions();
  }, [getUserAndSessions]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible" && user) {
        setSession(user);
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [user]);

  useEffect(() => {
    if (user) {
      window["mpSessionDebuggerMetadata"] = {
        userId: user.primaryEmail,
        userName:
          user.firstName && user.lastName
            ? user.firstName + " " + user.lastName
            : user.primaryEmail,
      };
    } else {
      window["mpSessionDebuggerMetadata"] = {};
    }
  }, [user]);

  return (
    <AuthContext.Provider
      value={{
        user,
        loading,
        authorized,
        sessions,
        setLoading,
        signIn,
        signOut,
        setUser,
        setSession,
        updateSessions,
        clearAuthData,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === null) {
    throw new Error("useAuth must be used within AuthProvider");
  }
  return context;
}
