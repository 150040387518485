export interface SessionDebuggerOptions {
  apiKey: string;
  version: string;
  application: string;
  environment: string;
  exporterApiBaseUrl?: string;
  /**
   * URLs that partially match any regex in ignoreUrls will not be traced.
   * In addition, URLs that are _exact matches_ of strings in ignoreUrls will
   * also not be traced.
   */
  ignoreUrls?: Array<string | RegExp>;
  recordButtonPlacement?: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
}

export interface SessionDebuggerConfigs {
  apiKey: string;
  version: string;
  application: string;
  environment: string;
  exporterApiBaseUrl: string;
  /**
   * URLs that partially match any regex in ignoreUrls will not be traced.
   * In addition, URLs that are _exact matches_ of strings in ignoreUrls will
   * also not be traced.
   */
  ignoreUrls: Array<string | RegExp>;
  recordButtonPlacement: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
}

export enum SessionState {
  started = '2',
  paused = '1',
  stopped = '0',
}
