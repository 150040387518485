export const getNavigatorInfo = () => {
  let browserInfo: string = 'Unknown'
  let deviceInfo: string = 'Unknown'
  let osInfo: string = 'Unknown'

  if (navigator.userAgent) {
    const userAgent = navigator.userAgent

    // Detect device type
    if (/Mobi|Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)) {
      deviceInfo = 'Mobile'
    } else if (/Tablet|iPad/i.test(userAgent)) {
      deviceInfo = 'Tablet'
    } else {
      // Default to desktop for other cases
      deviceInfo = 'Desktop'
    }

    // Detect browser and version
    if (userAgent.includes('Firefox')) {
      browserInfo = `Mozilla Firefox ${
        userAgent.match(/Firefox\/(\d+\.\d+)/)?.[1] || ''
      }`
    } else if (userAgent.includes('Chrome') && !userAgent.includes('Edg')) {
      browserInfo = `Google Chrome ${
        userAgent.match(/Chrome\/(\d+\.\d+)/)?.[1] || ''
      }`
    } else if (userAgent.includes('Edg')) {
      browserInfo = `Microsoft Edge ${
        userAgent.match(/Edg\/(\d+\.\d+)/)?.[1] || ''
      }`
    } else if (userAgent.includes('Safari') && !userAgent.includes('Chrome')) {
      browserInfo = `Safari ${userAgent.match(/Version\/(\d+\.\d+)/)?.[1] || ''}`
    } else if (userAgent.includes('Opera') || userAgent.includes('OPR')) {
      browserInfo = `Opera ${
        userAgent.match(/(Opera|OPR)\/(\d+\.\d+)/)?.[2] || ''
      }`
    } else if (userAgent.includes('Trident')) {
      browserInfo = `Internet Explorer ${
        userAgent.match(/rv:(\d+\.\d+)/)?.[1] || ''
      }`
    } else {
      browserInfo = 'Unknown browser'
    }

    // Detect OS and version
    if (userAgent.includes('Win')) {
      osInfo = `Windows ${userAgent.match(/Windows NT (\d+\.\d+)/)?.[1] || ''}`
    } else if (userAgent.includes('Mac')) {
      osInfo = `MacOS ${
        userAgent.match(/Mac OS X (\d+_\d+)/)?.[1].replace('_', '.') || ''
      }`
    } else if (userAgent.includes('Linux')) {
      osInfo = 'Linux'
    } else if (userAgent.includes('Android')) {
      osInfo = `Android ${userAgent.match(/Android (\d+\.\d+)/)?.[1] || ''}`
    } else if (userAgent.includes('iPhone') || userAgent.includes('iPad')) {
      osInfo = `iOS ${
        userAgent.match(/OS (\d+_\d+)/)?.[1].replace('_', '.') || ''
      }`
    } else {
      osInfo = 'Unknown OS'
    }
  }

  const hardwareConcurrency = navigator.hardwareConcurrency || 'Unknown'
  const cookiesEnabled = navigator.cookieEnabled ? 'Yes' : 'No'
  const pixelRatio = window.devicePixelRatio || 'Unknown'
  const screenSize = `${window.screen.width}x${window.screen.height}`

  return {
    osInfo,
    screenSize,
    pixelRatio,
    deviceInfo,
    browserInfo,
    cookiesEnabled,
    hardwareConcurrency,
  }
}

export const getFormattedDate = (date) => {
  return new Date(date).toLocaleDateString(
    'en-US',
    {
      month: 'short',
      year: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
      second: '2-digit',
    },
  )
}
