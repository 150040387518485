import { Resource } from '@opentelemetry/resources'
import { W3CTraceContextPropagator } from '@opentelemetry/core'
import { WebTracerProvider } from '@opentelemetry/sdk-trace-web'
import { BatchSpanProcessor } from '@opentelemetry/sdk-trace-base'
import * as SemanticAttributes from '@opentelemetry/semantic-conventions'
import { registerInstrumentations } from '@opentelemetry/instrumentation'
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http'
import { getWebAutoInstrumentations } from '@opentelemetry/auto-instrumentations-web'
// import { ZoneContextManager } from '@opentelemetry/context-zone'
import idGenerator from './id-generator'
import { SessionDebuggerConfigs } from 'src/types'
import { OTEL_MP_DEBUG_SESSION_ATTR } from './constants'

export class TracerBrowserSDK {
  private tracerProvider?: WebTracerProvider
  private configs
  private allowedElements = new Set<string>(['A', 'BUTTON'])
  private previousTargetedElementText = ''
  private sessionId = ''

  constructor() { }

  private setSessionId(sessionId: string) {
    this.sessionId = sessionId
    idGenerator.setSessionId(sessionId)
  }

  init(options: SessionDebuggerConfigs): void {
    this.configs = options
    const { application, version, environment } = this.configs
    this.tracerProvider = new WebTracerProvider({
      resource: new Resource({
        [SemanticAttributes.SEMRESATTRS_SERVICE_NAME]: `${application}-${environment}`,
        [SemanticAttributes.SEMRESATTRS_SERVICE_VERSION]: version,
        [SemanticAttributes.SEMRESATTRS_SERVICE_NAMESPACE]: environment,
      }),
      idGenerator,
    })
  }

  start(sessionId): void {
    if (!this.tracerProvider) {
      throw new Error('Configuration not initialized. Call init() before start().')
    }

    this.setSessionId(sessionId)

    this.tracerProvider.addSpanProcessor(
      new BatchSpanProcessor(
        new OTLPTraceExporter({
          url: `${this.configs.exporterApiBaseUrl}/v1/traces`,
          headers: { 'Authorization': this.configs.apiKey },
        }),
      ),
    )

    this.tracerProvider.addSpanProcessor({
      forceFlush: () => Promise.resolve(),
      onEnd: () => { },
      shutdown: () => Promise.resolve(),
      onStart: (span) => {
        if (this.sessionId?.length) {
          span.setAttribute(
            OTEL_MP_DEBUG_SESSION_ATTR,
            this.sessionId,
          )
        }
      },
    })

    this.tracerProvider.register({
      // contextManager: new ZoneContextManager(),
      propagator: new W3CTraceContextPropagator(),
    })

    registerInstrumentations({
      tracerProvider: this.tracerProvider,
      instrumentations: [
        getWebAutoInstrumentations({
          '@opentelemetry/instrumentation-xml-http-request': {
            clearTimingResources: true,
            ignoreUrls: [/\/v0\/radar\/debug-sessions/, ...this.configs.ignoreUrls || []],
            propagateTraceHeaderCorsUrls: /.*/,
          },
          '@opentelemetry/instrumentation-fetch': {
            clearTimingResources: true,
            ignoreUrls: [/\/v0\/radar\/debug-sessions/, ...this.configs.ignoreUrls || []],
            propagateTraceHeaderCorsUrls: /.*/,
          },
          '@opentelemetry/instrumentation-user-interaction': {
            shouldPreventSpanCreation: (_event, element: HTMLElement, span) => {
              if (!this.allowedElements.has(element.tagName)) {
                return true
              }
              const text = String(element.textContent || element.ariaLabel || '').trim()
              if (!text || this.previousTargetedElementText === text) {
                return true
              } else {
                this.previousTargetedElementText = text
              }
              span.setAttribute('target.innerText', text || '')
              return false
            },
          },
        }),
      ],
    })
  }

  stop(): void {
    if (!this.tracerProvider) {
      throw new Error('Configuration not initialized. Call init() before start().')
    }
    this.setSessionId('')
    this.tracerProvider.shutdown()
  }
}
