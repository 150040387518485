import { TracerBrowserSDK } from './otel'
import { RecorderBrowserSDK } from './rrweb'
import { getFormattedDate, getNavigatorInfo } from './helpers'
import { SessionWidget } from './sessionWidget'
import {
  SessionDebuggerConfigs,
  SessionDebuggerOptions,
  SessionState,
} from './types'
import './index.css'

export class MultiplayerSessionDebugger {
  private tracer = new TracerBrowserSDK()
  private recorder = new RecorderBrowserSDK()
  private sessionWidget = new SessionWidget()
  private configs: SessionDebuggerConfigs
  private sessionId: string | null = localStorage?.getItem(
    'multiplayer-debug-session-id',
  )
  private sessionState: SessionState = localStorage?.getItem(
    'multiplayer-debug-session-state',
  ) as SessionState

  public get error(): string {
    return this.sessionWidget.error
  }
  public set error(v: string) {
    this.sessionWidget.error = v
  }

  constructor() {
    this.configs = {
      apiKey: '',
      version: '',
      application: '',
      environment: '',
      exporterApiBaseUrl: 'https://api.multiplayer.app',
      ignoreUrls: [],
      recordButtonPlacement: 'bottom-right',
    }
  }

  init(configs: SessionDebuggerOptions): void {
    this.configs = { ...this.configs, ...configs }
    this.sessionWidget.init(this.configs)
    this.tracer.init(this.configs)
    this.recorder.init(this.configs)

    if (this.sessionId) {
      if (this.sessionState === SessionState.started) {
        this.start()
      } else if (this.sessionState === SessionState.paused) {
        this.sessionWidget.isPaused = true
      }
    }

    this.sessionWidget.on('toggle', (state: boolean, comment?: string) => {
      this.error = ''
      if (state) {
        this.createSession()
      } else {
        this.stopSession(comment?.trim())
        this.recorder.clearStoredEvents()
      }
    })

    this.sessionWidget.on('pause', () => {
      this.error = ''
      this.pause()
    })

    this.sessionWidget.on('cancel', () => {
      this.error = ''
      this.cancelSession()
      this.recorder.clearStoredEvents()
    })

    this.sessionWidget.on('preview', () => {
      this.recorder.preview()
    })
  }

  private createSession(): void {
    const clientMetadata = getNavigatorInfo()
    const metadata = window['mpSessionDebuggerMetadata'] || {}

    this.makeRequest('/debug-sessions/start', 'POST', {
      name: `Session on ${getFormattedDate(Date.now())}`,
      metadata,
      clientMetadata,
    })
      .then((session) => {
        this.setSession(session._id)
        this.start()
      })
      .catch((error) => {
        this.error = error.message
      })
  }

  private cancelSession(): void {
    this.makeRequest(`/debug-sessions/${this.sessionId}/cancel`, 'DELETE')
      .then(() => {})
      .catch((error) => {
        this.error = error.message
      })
    this.cancel()
  }

  private stopSession(comment?: string): void {
    this.makeRequest(`/debug-sessions/${this.sessionId}/stop`, 'PATCH', {
      userMetadata: { ...(comment?{ comment }:{}) },
    })
      .then(() => {
        // Session stopped
      })
      .catch((error) => {
        this.error = error.message
      })
    this.stop()
  }

  private start(): void {
    this.tracer.start(this.sessionId)
    this.recorder.start(this.sessionId)
    this.sessionWidget.isStarted = true
    this.sessionWidget.isPaused = false
    localStorage?.setItem(
      'multiplayer-debug-session-state',
      SessionState.started,
    )
  }

  private stop(): void {
    this.tracer.stop()
    this.recorder.stop()
    this.setSession('')
    this.sessionWidget.isStarted = false
    this.sessionWidget.isPaused = false
    localStorage?.setItem(
      'multiplayer-debug-session-state',
      SessionState.stopped,
    )
  }

  private pause(): void {
    this.tracer.stop()
    this.recorder.stop()
    this.sessionWidget.isStarted = false
    this.sessionWidget.isPaused = true
    localStorage?.setItem(
      'multiplayer-debug-session-state',
      SessionState.paused,
    )
  }

  private cancel(): void {
    this.setSession('')
    this.sessionWidget.isStarted = false
    this.sessionWidget.isPaused = false
  }

  private setSession(sessionId: string): void {
    this.sessionId = sessionId
    if (this.sessionId) {
      localStorage?.setItem('multiplayer-debug-session-id', this.sessionId)
    } else {
      localStorage?.removeItem('multiplayer-debug-session-id')
      localStorage?.removeItem('multiplayer-debug-session-state')
    }
  }

  private async makeRequest(
    url: string,
    method: string,
    body?: any,
  ): Promise<any> {
    if (!this.configs)
      return Promise.reject(new Error('Configurations not set'))
    try {
      const response = await fetch(
        `${this.configs.exporterApiBaseUrl}/v0/radar${url}`,
        {
          method: method,
          body: body ? JSON.stringify(body) : null,
          headers: {
            'Content-Type': 'application/json',
            'X-Api-Key': this.configs.apiKey,
          },
        },
      )
      if (!response.ok) {
        throw new Error('Network response was not ok ' + response.statusText)
      }
      try {
        return await response.json()
      } catch (error) {
        return null
      }
    } catch (error) {
      throw new Error('Something went wrong')
    }
  }
}
